import { Container } from "../Grid"
import ShareDropdown from "../ShareDropdown"
import Subscribe from "../Subscribe"
import Icon from "../Icon"
import Menu from "../Menu"
import { Link } from "gatsby"
import HamburgerIcon from "../Hamburger"
import { login, logout, getError } from "../../utils/auth"
import { AuthConsumer } from "../../context/AuthContext"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames"
import CVSLogo from "../../images/theme/logo-cvs-health.svg"
import DiscoveryLogo from "../../images/global/img-de-logo.png"
import "./styles.scss"

const NAV_BREAKPOINT = 1024;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuOpen: false,
    };

    this.resizeListener = this.resizeListener.bind(this);
  }

  resizeListener = () => {
    if (window.innerWidth > NAV_BREAKPOINT && this.state.menuOpen) {
      this.setState({ menuOpen: false });
    }
  }

  componentDidMount() {
    if (window) {
      window.addEventListener('resize', this.resizeListener);
    }
  }

  componentWillUnmount() {
    if (window) {
      window.removeEventListener('resize', this.resizeListener);
    }
  }

  handleHamburgerClick = () => {
    this.setState(state => ({
      menuOpen: !state.menuOpen,
    }))
  }

  render() {
    const { data } = this.props
    const { menuOpen } = this.state
    const error = getError()

    return (
      <AuthConsumer>
        {isAuthenticated => (
          <header className="header">
            <Container>
              <div className="header__logos">
                <div className="header__logos-branding">
                  <a
                    href="https://cvshealth.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="cvs__logo"
                  >
                    <img src={CVSLogo} alt={data.site.siteMetadata.partner} />
                  </a>
                  <Link to="/" className="de__logo">
                    <img
                      src={DiscoveryLogo}
                      alt={data.site.siteMetadata.partner}
                    />
                  </Link>
                </div>
                <Link to="/" className="dose__knowledge">
                  <h2 className="campaign__title">Dose of Knowledge</h2>
                </Link>
              </div>
              <div
                className={classNames(
                  "header-links",
                  menuOpen && "header-links--open"
                )}
              >
                <div className="utility__links">
                  <div className="header__signup">
                    <Subscribe
                      trigger={
                        <button>
                          <Icon name="signup" inline />
                          eNews Updates
                        </button>
                      }
                    />
                  </div>
                  <div className="header__share">
                    <ShareDropdown />
                  </div>
                  <div className="header__signin">
                    <button onClick={isAuthenticated ? logout : login}>
                      CVS Health Employee Sign {isAuthenticated ? "Out" : "In"}
                    </button>
                  </div>
                </div>
                <nav className="primary__nav-container">
                  <Menu />
                </nav>
              </div>{" "}
              <HamburgerIcon
                onClick={this.handleHamburgerClick}
                isActive={menuOpen}
              />
            </Container>
            {error && (
              <Container>
                <div className="header__error">{error}</div>
              </Container>
            )}
          </header>
        )}
      </AuthConsumer>
    )
  }
}

Header.propTypes = {
  data: PropTypes.object.isRequired,
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            partner
          }
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
)
