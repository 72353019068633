import React from "react"
import "./styles.scss"

const Container = ({ children }) => (
  <div className="container">
    {children}
  </div>
)

export default Container
